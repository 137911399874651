<template>
    <div id="app" :style="{height:bodyHeight + 'px'}" :class="flagPc ? 'pc-width':'phone-width'">
        <router-link to="/start"></router-link>
        <router-view/>
        <!--返回urnature-->
        <div @click="backToUrnature" v-if="isWeb" class="back-anchor">
            <i class="el-icon-arrow-left"></i>
            UrNature
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                //是否为电脑端
                flagPc: false,
                bodyHeight: 0,
                //是否为网页版
                isWeb: true
                // isWeb: false
            }
        },
        watch: {
            '$i18n.locale'(newLocale) {
                this.updateDocumentTitle();
            }
        },
        created() {
            this.updateDocumentTitle();
        },
        mounted() {
            this.init();
            // window.onresize = () => {
            //     return (() => {
            //         this.init();
            //     })()
            // }
        },
        methods: {

            /**
             * 判断手机端还是电脑端，并设置高度
             */
            init() {
                let userAgentInfo = navigator.userAgent;
                let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
                this.flagPc = true;
                for (let v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        this.flagPc = false;
                        break;
                    }
                }

                if (this.flagPc) { //PC端
                    this.bodyHeight = 812;
                    document.documentElement.style.setProperty('--body-height', '812px');
                    document.documentElement.style.setProperty('--body-width', '375px');
                } else { //手机端
                    this.bodyHeight = window.innerHeight;
                    this.bodyWidth = document.documentElement.clientWidth;
                    document.documentElement.style.setProperty('--body-height', this.bodyHeight + 'px');
                    document.documentElement.style.setProperty('--body-width', this.bodyWidth + 'px');
                }
            },

            /**
             * 更新页面标题,
             * 避免出现切换语言后，页面标题不更新的问题
             */
            updateDocumentTitle() {
                let routeTitle = this.$route.meta.title;
                document.title = this.$t(routeTitle);
            },

            /**
             * 返回urnature
             */
            backToUrnature() {
                // location.replace('http://10.0.0.168:8081/home')
                // location.replace('http://124.239.168.199:6041/home')
                location.replace('https://www.urnature.net/home')
            }
        }
    }
</script>

<style lang="less">
    #app {
        /*height: 100%;*/
        /*width: 100%;*/
        font-family: Arial, Avenir, Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        color: #333;
        position: relative;
    }

    .back-anchor {
        position: absolute;
        left: 0;
        top: 10px;
        /*bottom: calc(0.098 * var(--body-height) + 60px);*/
        width: 70px;
        height: 30px;
        color: #fff;
        font-size: 10px;
        line-height: 30px;
        background: #7292B7;
        border-radius: 0 20px 20px 0;
    }

    .pc-width {
        width: 375px;
        margin: 0 auto;
    }

    .phone-width {
        width: 100%;
    }

</style>
