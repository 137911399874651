import router from './router'
import store from './store'
import {
    getToken, removeToken,
    removeUserId,
} from './user_info/auth'
import i18n from './i18n/index'


// 白名单
const whiteList = ['/login']

/**
 * 路由全局前置守卫
 */
router.beforeEach((to, from, next) => {
    //设置标题
    if (to.meta && to.meta.title) {
        document.title = i18n.t(to.meta.title); // 设置页面标题
    }
    // if (to.meta.title) {
    //     document.title = to.meta.title
    // }
    let token = getToken()
    if (token) {
        store.dispatch('pullUserInfo').then(resp => {
            if (resp === null) {
                removeToken()
                removeUserId()
                next('/login')
            } else {
                if (to.path === '/login') {
                    next('/start')
                } else if (to.path === '/') {
                    next('/start')
                } else if (from.path === '/end') {
                    if (to.path === '/statistics') {
                        next();
                    } else if (to.path === '/start') {
                        next()
                    } else {
                        next('/start')
                    }
                } else if (from.path === '/statistics') {
                    if (to.path !== '/start') {
                        next('/start');
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            }
        }).catch(res => {
            removeToken()
            removeUserId()
            next('/login')
        })
    } else {
        if (to.path === '/login') {
            next()
        } else {
            next('/login')
        }
    }
})

