import Vue from 'vue'
import App from './App.vue'

//浏览器发布守卫
import './routerGuard'
//app发布守卫
// import './routerGuardApp'
import i18n from './i18n/index'

import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import {Toast, Slider,Field,Popup,Picker } from 'vant';
import 'vant/lib/index.css';


Toast.setDefaultOptions({duration: 800});

import {message} from './rewrite/message.js';

import '../src/css/common.css';
import '../src/css/icon.css';

Vue.prototype.$busEvent = new Vue()


Vue.use(ElementUI);

Vue.use(Toast)
Vue.use(Slider)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)


Vue.prototype.$message = message;
Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
