<template>
    <div class="start-back">
        <div style="height:calc(0.23 * var(--body-height));"></div>
        <div style="text-align:center">
            <img src="@/assets/img/heart.png" class="heart-pic" alt="">
        </div>
        <div class="questionnaire-title">{{$t('ema')}}</div>
        <div @click="checkRule" class="integral-rule">{{$t('integralRule')}}>></div>
        <div class="btn-wrap">
            <el-button @click="toAnswer"
                       :loading="btnLoading"
                       class="orange-btn">{{$t('startToAnswer')}}
            </el-button>
        </div>
    </div>
</template>

<script>
    import api from '@/api'
    import {mapGetters} from "vuex";

    export default {
        name: "index",
        data() {
            return {
                btnLoading: false
            }
        },
        computed: {
            ...mapGetters([
                'userId',
                'natureAnswerArr',
                'sliderAnswer',
                'mentalRadioAnswer'
            ]),
        },
        mounted() {
            this.getLang();
          // let this_ = this
          // window.addEventListener("message",(ev)=>{
          //   if (ev.data.token) {
          //     const token = ev.data.token;
          //     const userId = ev.data.userId;
          //     this_.$store.commit("SET_USERTOKEN",token)
          //     this_.$store.commit("SET_ID",userId)
          //     //填写原本的mounted中执行的逻辑代码
          //     this_.getLang();
          //   }
          // })
        },
        methods: {

            /**
             * 获取语言
             */
            getLang() {
                api.questionnaire.getUserDetail(this.userId).then(res => {
                    let language = res.data.result.userDetailsInfo.language
                    localStorage.setItem('userLang', language)
                    this.$i18n.locale = language
                })
            },

            /**
             * 查看规则
             */
            checkRule() {
                this.$router.push('/rule')
            },

            /**
             * 去答题
             */
            toAnswer() {
                this.btnLoading = true
                let that = this
                api.questionnaire.isLessTen(this.userId).then(res => {
                    let arr = res.data.result
                    that.$store.commit('SET_HEALTH_FLAG',arr[1])
                    that.$store.commit('SET_AGGRESSION_FLAG',arr[2])
                    if (arr[0] === 0) { //答题正常
                        //获取用户定位
                        let geolocation = new BMapGL.Geolocation();
                        geolocation.enableSDKLocation();
                        geolocation.getCurrentPosition(function (r) {
                            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                                that.resetVuex();
                                that.$store.commit('SET_LAT', r.latitude)
                                that.$store.commit('SET_LON', r.longitude)
                                that.btnLoading = false
                                that.$router.push('/notice')
                            } else {
                                that.$toast(that.codeToWord(this.getStatus()));
                                that.resetVuex();
                                that.btnLoading = false
                                that.$router.push('/notice')
                            }
                        })
                    } else if (arr[0] === 1) { //答题超过10次
                        that.$toast(this.$t('startTip'))
                        that.btnLoading = false
                    } else { //答题频繁
                        that.$toast(this.$t('startTip2'))
                        that.btnLoading = false
                    }
                }).catch(err=>{
                    that.btnLoading = false
                })

            },

            /**
             * 报错码含义
             */
            codeToWord(code) {
                switch (code) {
                    case 6:
                        return '没有权限，定位被拒绝'
                    // case 2:
                    //     return '定位不可用'
                    // case 8:
                    //     return '定位超时'
                    default:
                        return '未获取到定位'
                }
            },

            /**
             * 重置vuex
             */
            resetVuex() {
                this.$store.commit('SET_LAT', 0)
                this.$store.commit('SET_LON', 0)

                this.$store.commit('SET_LOCATION1_ANSWER', '')
                this.$store.commit('SET_LOCATION2_ANSWER', ['', ''])
                this.$store.commit('SET_LOCATION3_ANSWER', ['', ''])
                this.$store.commit('SET_LOCATION4_ANSWER', '')

                this.$store.commit('SET_NATURE_ANSWER_ARR', [
                    [1, ''],
                    [2, ''],
                    [3, '']
                ])
                this.$store.commit('SET_SLIDER_ANSWER', [
                    [1, 0],
                    [2, 0],
                    [3, 0],
                    [4, 0],
                    [5, 0],
                    [6, 0]
                ])
                this.$store.commit('SET_MENTAL_RADIO_ANSWER', [
                    [1, ''],
                    [2, ''],
                    [3, ''],
                    [4, ''],
                    [5, ''],
                    [6, ''],
                    [7, ''],
                ])

                this.$store.commit('SET_CAMERA_SHOW', true)
                this.$store.commit('SET_PHOTO_FILE', null)
                this.$store.commit('SET_AUDIO_FILE', null)
                this.$store.commit('SET_AUDIO_URL', null)

            }
        }
    }
</script>

<style scoped lang="less">

    .start-back {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/startBack.png");
        background-size: cover;
    }

    .heart-pic {
        height: calc(0.13 * var(--body-height));
    }

    .questionnaire-title {
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
        text-align: center;
        /*margin: 4.187vh 0 1.847vh;*/
        margin: calc(0.041 * var(--body-height)) 0 calc(0.018 * var(--body-height));

    }

    .integral-rule {
        font-size: 14px;
        color: #333333;
        text-align: center;
    }

    .btn-wrap {
        position: absolute;
        left: 0;
        bottom: calc(0.174 * var(--body-height));
        width: 100%;
        text-align: center;
    }

    .orange-btn {
        width: 200px;
        color: #fff;
        font-size: 16px;

        /*height: 35px;*/
        background: #F6B122;
        border: none;
        /*border-radius: 10px;*/
    }


</style>
